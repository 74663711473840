<script lang="ts" setup>
  import { useShared } from '@plenny/visitor';

  const shared = useShared();
</script>
<template>
  <div class="newsletter-banner">
    <Newsletter class="newsletter-banner__newsletter" />
  </div>
  <footer class="footer">
    <div class="footer__top container">
      <div class="footer__block footer__block--contact">
        <SfLogo class="footer__logo" />
        <div class="footer__contact-wrapper" v-if="shared.department">
          <span class="footer__headquarters">{{ $t('Główna siedziba') }}</span>
          <a :href="shared.department.maps || '#'" class="footer__address-link footer__contact-item" target="_blank">
            <div class="footer__address">
              <span v-if="shared.department.first_line" v-text="shared.department.first_line" />
              <span v-if="shared.department.second_line" v-text="shared.department.second_line" />
              <span v-if="shared.department.third_line" v-text="shared.department.third_line" />
            </div>
          </a>
        </div>
      </div>
      <div v-for="group in shared.menu.menu_bottom" class="footer__block">
        <span class="footer__block-title">{{ group.name }}</span>
        <ul class="footer__list">
          <li v-for="item in group.children" class="footer__list-item">
            <FooterNavItem :item="item" class="link" />
          </li>
        </ul>
      </div>
      <div class="footer__block footer__block--contact-link">
        <div class="footer__contact-wrapper" v-if="shared.department">
          <span class="footer__block-title">{{ $t('Kontakt') }}</span>
          <a v-if="shared.department.email" :href="`mailto:${shared.department.email}`" class="footer__phone-link footer__contact-item">
            <IconEnvelope />
            <span v-html="shared.department.email" />
          </a>
          <a v-if="shared.department.phone" :href="`tel:${shared.department.phone}`" class="footer__phone-link footer__contact-item">
            <IconPhone />
            <span v-html="shared.department.phone" />
          </a>
        </div>
      </div>
    </div>
    <Separator />
    <div class="footer__bottom">
      <div class="container footer__bottom-content">
        <div>
          <CopyrightMessage />
        </div>
        <div>
          <p>
            {{ $t('Realizacja') + ': ' }}<a href="https://promoznawcy.pl/" target="_blank">PROMOznawcy.pl</a>
          </p>
        </div>
      </div>
    </div>
    <FixedActions />
  </footer>
</template>
<style lang="scss" scoped>

  .newsletter-banner {
    position: relative;
    background-image: url("../../../assets/images/newsletter-banner.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    z-index: 0;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.6);
      z-index: -1;
    }
  }

  .footer {
    padding-top: 65px;
    color: var(--color-main-black);
    background-color: var(--color-light-gray);
    border-top: 5px solid var(--primary-color);

    a {
      transition-property: color;
      transition-duration: var(--transition-primary-duration);
      transition-timing-function: var(--transition-primary-timing-function);

      &:hover {
        color: var(--primary-color-hover);
      }
    }

    &__logo {
      max-width: 260px;
    }

    &__top {
      display: flex;
      justify-content: space-between;
      padding-bottom: 4rem;
    }

    &__contact-wrapper {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      svg {
        font-size: 2rem;
      }
    }

    &__headquarters {
      font-size: 1.5rem;
      font-weight: var(--font-weight-700);
    }

    &__contact-item {
      display: flex;
      gap: 2rem;
      align-items: center;
      font-weight: var(--font-weight-600);
    }

    &__address {
      display: flex;
      flex-direction: column;
    }

    &__block {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      gap: 3rem;
      padding: 0 2.5rem;

      &:first-child {
        padding-left: 0;
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 1rem;
      list-style: none;
    }

    &__block-title {
      font-size: 1.5rem;
      font-weight: var(--font-weight-700);
    }

    &__bottom {
      padding: 1rem 0;
      background-color: var(--primary-color);
    }

    &__bottom-content {
      display: flex;
      justify-content: space-between;
      font-size: 0.625rem;

      a:hover {
        color: var(--color-theme-blue);
      }
    }
  }

  @media (max-width: 1200px) {
    .footer {
      &__top {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 4rem;
      }

      &__block:first-child {
        padding-left: 2.5rem;
      }
    }
  }

  @media (max-width: 992px) {
    .footer {
      padding-top: 2.5rem;

      &__logo {
        max-width: 250px;
      }

      &__top {
        grid-template-columns: 1fr;
        gap: 2.5rem;
        padding: 0 1rem 2rem;
      }

      &__block {
        padding: 0;
        gap: 1rem;

        &:first-child {
          padding-left: 0;
        }
      }

      &__block--contact {
        gap: 2.5rem;
        order: -2
      }

      &__block--contact-link {
        order: -1
      }

      &__bottom {
        padding: 1rem;
      }

      &__bottom-content {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        text-align: center;
      }
    }
  }
</style>
